import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => {
  const productName = "UFlow"
  return (
    <Layout>
      <SEO title={`${productName} 隐私条例`} />
      <h1 className="sp-title">{productName} 隐私条例</h1>
      <div className="sp-body">
        <div className="sp-content">
          <p>
            {productName}
            非常重视对您的个人隐私保护，有时候我们需要某些信息才能为您提供您请求的服务，本隐私声明解释了这些情况下的数据收集和使用情况。
          </p>
          <p>
            本隐私声明适用于 {productName}
            的所有相关服务。若您注册成为用户并使用我们的服务，则视为您同意本隐私条款的全部内容，并同意受本隐私条款的约束；若您不同意本隐私条款，请立即停止注册或主动停止使用我们的服务。
          </p>
          <h2>1. 个人信息的收集</h2>
          <p>
            当我们需要能识别您的个人信息或者可以与您联系的信息时，我们会征求您的同意。通常，在您注册
            {productName}
            或申请开通新的功能时，我们可能收集您的个人信息，包括但不限于姓名，Email地址和电话号码，并征求您的确认。
          </p>
          <h2>2. 个人信息的使用</h2>
          <p>
            {productName}
            将严格保护你的个人隐私信息。我们使用各种安全技术和程序来保护您的个人信息不被未经授权的访问、使用或泄露。
            {productName}
            承诺不会向任何第三方提供、出售、出租、分享和交易用户的个人信息；承诺不会在未得到您事先许可的情况下，将您的个人信息对外披露或向第三方提供，除非第三方和
            {productName} 一起为网站和用户提供服务。且 {productName}
            将采取必要的技术手段禁止该等第三方在合作结束后访问其以前能够访问的包括您的个人信息在内的所有资料。
          </p>
          <h2>3. 免责说明</h2>
          <p>就下列相关事宜的发生，{productName} 不承担任何法律责任：</p>
          <ul>
            <li>
              由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人信息的泄露，或其他非因
              {productName} 原因导致的个人信息的泄露；
            </li>
            <li>
              {productName}
              根据法律的有关规定，政府相关政策，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
            </li>
            <li>
              任何第三方根据 {productName}
              各服务条款及声明中所列明的情况使用您的个人信息，由此所产生的纠纷；
            </li>
            <li>
              任何由于黑客攻击、电脑病毒侵入或政府管制而造成的暂时性网站关闭；
            </li>
            <li>因不可抗力导致的任何后果；</li>
            <li>
              {productName} 在各服务条款及声明中列明的使用方式或免责情形。
            </li>
          </ul>
          <h2>4. 协议修改</h2>
          <p>
            根据互联网有关法律、法规，或者因业务发展需要，{productName}
            有权更新隐私声明的内容。{productName}
            会另行通知您。更新后的隐私声明一旦在网页上公布即有效代替原来的隐私声明。
          </p>
          <p>
            如果不同意 {productName}{" "}
            对本协议相关条款的修改，用户有权并应当停止使用
            {productName}。如果用户继续使用 {productName}，则视为用户接受{" "}
            {productName}
            对本协议相关条款的修改。
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default SecondPage
